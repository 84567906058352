import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser")) || null
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is Admin
   */
  get isAdmin() {
    return (
      this.currentUser &&
      (this.currentUserSubject.value.type & Role.Admin) === Role.Admin
    );
  }

  /**
   *  Confirms if user is rider
   */
  get isRider() {
    return (
      this.currentUser &&
      (this.currentUserSubject.value.type & Role.Rider) === Role.Rider
    );
  }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */

  public static formData(obj: Object) {
    //to utils
    let ret = new FormData();
    Object.keys(obj).forEach((k) => ret.append(k, obj[k]));
    return ret;
  }

  login(username: string, password: string) {
    let fd = new FormData();
    return this._http
      .post<any>(
        `${environment.apiUrl}/amoy/auth/login`,
        AuthenticationService.formData({ mobile: username, password })
      )
      .pipe(
        map((d) => {
          console.log(d);
          // login successful if there's a jwt token in the response
          // check if user is rider type = 1
          if ((d?.code == 0 || d.token) && d?.data?.type == 1) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(d.data));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                "You have successfully logged in. Enjoy! 🎉",
                "👋 Welcome, " + d.data.fname + "!",
                { toastClass: "toast ngx-toastr", closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(d.data);
            return d.data;
          }
          throw new Error(`code:${d?.code} msg:${d?.msg}`);
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    // notify
    this.currentUserSubject.next(null);
  }
}
