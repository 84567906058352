<div class="modal-dialog sidebar-lg">
    <div class="modal-content p-0">
        <!-- START: Modal Header -->
        <div class="setting" data-bs-scroll="true" tabindex="-1" aria-labelledby="offcanvasBothLabel">
            <div class="offcanvas-header fixed">
                <h4 id="offcanvasBothLabel" class="offcanvas-title fw-bolder">{{ 'ORDERS' | translate }}#</h4>
                <button type="button" class="btn-close text-reset"  data-bs-dismiss="offcanvas" aria-label="Close" ></button>
            </div>
        </div>
        <!-- END: Modal Header -->

        <!-- START: Modal Body -->
        <div class="offcanvas-body mx-0 flex-grow-0">
            content

        </div>
        <!-- END: Modal Body -->

        <!-- START: Modal Footer -->
        <div class="offcanvas-footer">
            <button type="button" class="btn round btn-primary mb-1 d-grid w-100" >{{ "SUBMIT" | translate }}</button>
        </div>
        <!-- End: Modal Footer -->
    </div>

</div>