import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verification-sidebar',
  templateUrl: './verification-sidebar.component.html',
  styleUrls: ['./verification-sidebar.component.scss']
})
export class VerificationSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
