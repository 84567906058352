import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  public token: any;
  public url: string = environment.apiUrl;

  constructor(private _authenticationService: AuthenticationService,
    private http: HttpClient) {
    this.token = JSON.parse(localStorage.getItem('currentUser'))?.token;
    
   }

  
  getCity(start, end, token) : Observable<any> {
    // start = '20230328200000';
    // end = '20230528200000';

    let url = this.url+`/mall/rider-ex/cities?start=${start}&end=${end}`
    // url = "api/cities-data"
    let headers = {headers: new HttpHeaders({
      'token': token,
    })};

    return this.http.get<any>(url, headers);
  }

  getOrdersByCity(start, end, city, token) {
    // console.log(start,end)
    // start = '20230328200000';
    // end = '20230528200000';
    let city_par = ""
    if(city != ''){
      city_par ='&city='+city
    }
    let url = this.url+`/mall/rider-ex/orders?start=${start}&end=${end}` + city_par;
    console.log(url)
    let headers = {headers: new HttpHeaders({
      'token': token
    })};
    // url = "api/buildings-data"
    return this.http.get<any>(url, headers);
  }


  getOrderDetails( orderIds, token) {
    let url = this.url+`/mall/rider-ex/orders-detail?ids=${orderIds}`; //ids=1328,1329
    // url = "api/orders-data"
    let headers = {headers: new HttpHeaders({
      'token': token
    })};
    console.log(url)
    return this.http.get<any>(url, headers);
  }

  // upload picture
  uploadPicture(file, token) {
    var formData: any = new FormData();
    formData.append('thumb', file);
    console.log(formData)
    let headers = {headers: new HttpHeaders({
      'token': token
    })};
    return this.http.post(this.url + '/mall/users/upload', formData, headers);
  }

  // update mutiple orders information
  updateOrders(orders, token) {
    var formData: any = new FormData();
    formData.append('updates', JSON.stringify(orders));
    console.log(formData)
    let headers = {headers: new HttpHeaders({
      'token': token
    })};
    return this.http.post(this.url + '/mall/rider-ex/update-orders', formData, headers);
  }

  getLocation(term: string): Observable<GeocoderResponse> {
    const url = `https://maps.google.com/maps/api/geocode/json?address=${term}&sensor=false&key=${environment.googleApiKey}`;
    return this.http.get<GeocoderResponse>(url);
  }
}

export class GeocoderResponse {
  status: string;
  error_message: string;
  results: google.maps.GeocoderResult[];

  constructor(status: string, results: google.maps.GeocoderResult[]) {
    this.status = status;
    this.results = results;
  }
}
