import { Role } from './role';

export class User {
  id: number;
  email: string;
  mobile: string;
  password: string;
  // firstName: string;
  lastName: string;
  avator: string;
  role: Role;
  token?: string;
  fname: string;
  type: number;
}
