import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  { 
    id: 'general',
    type: 'section',
    title: 'GENERAL',
    children:[
    {
      id: 'delivery',
      title: 'Deliveries',
      translate: 'MENU.SAMPLE',
      type: 'item',
      icon: 'box',
      url: 'home'
    },
    {
      id: 'rider_order_history',
      title: 'History',
      translate: 'MENU.SAMPLE',
      type: 'item',
      icon: 'clipboard',
      url: 'rider-order-history'
    },
    // {
    //   id: 'building_detail',
    //   title: 'Building',
    //   translate: 'MENU.SAMPLE',
    //   type: 'item',
    //   icon: 'box',
    //   url: 'building-detail'
    // }
  ]
  }
]
