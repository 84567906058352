
<div class="content-wrapper container-xxl p-0">
    <div class="content-header row">
        <!-- BEGIN: Page Header-->
        <div class="content-header-left col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12 d-flex">
                    <h2 class="content-header-title float-left mb-0 ">{{ 'DELIVERIES' | translate }}</h2>
                    <div class="breadcrumb-wrapper d-flex">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">{{ 'HOME' | translate }}</a>
                            </li>
                            <li class="breadcrumb-item active">{{ 'DELIVERIES' | translate }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Page Header-->
    </div>
    <button (click)="openSidebar()">openSidebar</button>

</div>

<core-sidebar class="modal modal-slide-in fade event-sidebar" name="verification-sidebar" overlayClass="modal-backdrop"
hideOnEsc="true">
    <app-verification-sidebar> </app-verification-sidebar>
</core-sidebar>
