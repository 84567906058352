import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { AuthenticationService } from 'app/auth/service';
import { CityService } from 'app/services/city.service';
import { UserService } from 'app/services/user.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public url: string = environment.apiUrl;

  public currentUser: any;
  areaList = 0;
  cityList = [];
  buildingList = [];
  startTime: string;
  endTime: string;
  building_length = 0;
  delivery_length = 0;

  public date: any={ d:moment().format(("D")), w: moment().format("ddd")};
  constructor(
    private _authenticationService: AuthenticationService,
    private cityService: CityService,
    private _httpClient: HttpClient,
    private router: Router,
    private _coreSidebarService: CoreSidebarService) {
   }

  changeTab(input, city){
    // console.log(input)
    this.areaList=input;

    this.getOrdersByCity(this.startTime, this.endTime, city)
  }

  ngOnInit(): void {
    // console.log(this.platform.SAFARI)
    this.currentUser = this._authenticationService.currentUserValue;

    this.startTime = moment().hour(8).minute(0).second(0).format('YYYYMMDDHHmmss');
    this.endTime = moment().hour(20).minute(0).second(0).format('YYYYMMDDHHmmss');

    localStorage.setItem('searchTime', this.startTime+'|'+this.endTime);
    this.getCities(this.startTime, this.endTime);
    this.getOrdersByCity(this.startTime, this.endTime, '');
  }

  refresh(){
    this.areaList=0;
    this.getCities(this.startTime, this.endTime);
    this.getOrdersByCity(this.startTime, this.endTime, '');
  }

  // get all cities by rider token and date
  getCities(start, end) {
    this.cityService.getCity(start, end, this.currentUser.token)
        .subscribe(
          (res:any) => {
            // console.log(res)
            if(res.code == 100){
              this.router.navigate(['/pages/authentication/login-v2']);
            }
            this.cityList = res.msg
          }, 	
          err => {
            this.router.navigateByUrl('/pages/authentication/login-v2');
            // console.log(`auth err : ${JSON.stringify(err)}`)
          }
        )
  }

  // get all orders by city
  getOrdersByCity(start, end, city) {
    this.cityService.getOrdersByCity(start, end, city, this.currentUser.token)
        .subscribe(
          (res : any) => {
            // console.log(res)
            if(res.code == 100){
              this.router.navigate(['/pages/authentication/login-v2']);
            }
            this.buildingList = res.msg;
            if(city == ''){
              this.building_length = res.msg.length;
              // cal deliveries length
              this.delivery_length = 0;
              for(let b of res.msg){
                this.delivery_length += b.ids.split(',').length;
              }
            }
          }, 	
          err => {
            this.router.navigateByUrl('/pages/authentication/login-v2');
            // console.log(`auth err : ${JSON.stringify(err)}`)
          }
        )
  }

  redirect(url, buildingList){
    localStorage.setItem('currentBuildingList', JSON.stringify(buildingList));
    this.router.navigateByUrl(url);
  }

  openAppMap(type, location){
    // 49.35423° N
    if (type == 'apple'){
      window.open(`maps://maps.google.com/maps?daddr=${location}&dirflg=d`);

    }
    if (type == 'google'){
      window.open(`https://maps.google.com/maps/dir/?api=1&origin=&destination=${location}&mode=drive`);
    }
    // this.cityService.getLocation("5674 Jersey, Burnaby, BC")
    // .subscribe(
    //   (res : any) => {
    //     console.log(res)
    //     // window.open('https://maps.google.com/maps/dir/?api=1&origin=Space+Needle+Seattle+WA&destination=Pike+Place+Market+Seattle+WA&travelmode=bicycling')

    //   }, 	
    //     err => console.log(`auth err : ${JSON.stringify(err)}`)
    //   )
  }

  openSidebar(){
    // console.log("here")
    this._coreSidebarService.getSidebarRegistry('verification-sidebar').toggleOpen();
}

}
// function mapsSelector() {
//   if /* if we're on iOS, open in Apple Maps */
//     ((navigator.platform.indexOf("iPhone") != -1) || 
//      (navigator.platform.indexOf("iPad") != -1) || 
//      (navigator.platform.indexOf("iPod") != -1))
//     window.open("maps://maps.google.com/maps?q=1200 Pennsylvania Ave SE, Washington, District of Columbia, 20003");
//   else /* else use Google */
//       window.open("https://maps.google.com/maps?daddr=<lat>,<long>&amp;ll=");
//   }