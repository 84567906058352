import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { HomeComponent } from './home.component';

//cooper
import {AuthGuard} from 'app/auth/helpers/auth.guards'
import { Role } from 'app/auth/models';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VerificationSidebarComponent } from './verification-sidebar/verification-sidebar.component';
import { CoreSidebarModule } from '@core/components';

const routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { animation: 'home',roles: [Role.Rider, Role.Admin] },
	  // canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [HomeComponent, VerificationSidebarComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule,
   CorePipesModule, NgbModule, CoreSidebarModule],
  exports: []
})
export class HomeModule { }
