import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

//cooper start
import {fakeBackendProvider} from 'app/auth/helpers/fake-backend';
import { HomeModule } from './main/home/home.module';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { WebcamModule } from 'ngx-webcam';
import { GoogleMapsModule } from '@angular/google-maps';
import {PlatformModule} from '@angular/cdk/platform';



const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'building-detail',
    loadChildren: () => import('./main/building-detail/building-detail.module').then(m => m.BuildingDetailModule),
  },
  {
    path: 'rider-order-history',
    loadChildren: () => import('./main/rider-order-history/rider-order-history.module').then(m => m.RiderOrderHistoryModule),
  },
  {
    path:'sample',
    loadChildren: () => import('./main/sample/sample.module').then(m => m.SampleModule),
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
      useHash: true
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
	  HomeModule,
    


    // webcam
    WebcamModule,

    // GoogleMapsModule
    GoogleMapsModule,

    // detect device
    PlatformModule,

    // fake backend
    // HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
    //   delay: 0,
    //   passThruUnknownUrl: true
    // }),
    
  ],
  bootstrap: [AppComponent],
  providers: [] //cooper fakeBackendProvider
})
export class AppModule {}
